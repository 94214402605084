import * as React from "react";
import { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import {
  dataEndpointConfig,
  getDeviceData,
  DeviceProps,
  getIndexByObjectImei,
  getOverviewImeiList,
  getUrlParams,
  maybeParseJson,
  DataProps,
} from "../data";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  AlwaOverview,
  AlwaDetails,
  AlwaDevice,
  AlwaDeviceDetailDefault,
  AlwaDeviceDetailProps,
  currentDeviceProps,
  getDevicesFromWallImei,
  joinAlwaDetailData,
  joinAlwaOverviewData,
  getDevicesFromRoomImei,
  parseAlwaUpdateData,
  transformAlwaUpdate,
} from "../components/ProjectComponents";
import { StandardTemplate } from "../components/Templates";
import {
  Section,
  Row,
  LoadingScreen,
  RedAnimation,
  HeadlineContainer,
  Headline,
} from "../components/GlobalComponents";

const client = new W3CWebSocket(`wss://nodered.vodafone.dev/ws/iot-data`);

const AlwaPage = () => {
  const [view, setView] = useState<string>("overview");
  const [currentDevice, setCurrentDevice] = useState<currentDeviceProps>({
    index: 0,
    wall: "",
    room: "",
    pump: "",
    lamp: "",
  });
  const [deviceDetail, setDeviceDetail] = useState<AlwaDeviceDetailProps>(
    AlwaDeviceDetailDefault
  );
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [overviewData, setOverviewData] = useState<DeviceProps[]>([]);
  const [loading, setLoading] = useState(true);

  /**
   * Websocket Connection for live data
   */
  useEffect(() => {
    client.onclose = () => {
      alert("Backend connection lost.");
    };
    client.onmessage = (message) => {
      const data = maybeParseJson(message.data);
      if (data.topic && data.topic === "iotData") {
        if (data.data.type && data.data.type === "socketStatus") {
          const index = getIndexByObjectImei(
            overviewData as unknown as DeviceProps[],
            data.imei
          );
          const devices = getDevicesFromWallImei(overviewData[index].imei);
          if (data.data.device === devices.pump) {
            setOverviewData((oldOverviewState) => {
              const newState = [...oldOverviewState];
              newState[index].data.pump = data.data.status === "ON";
              return newState;
            });
          } else if (data.data.device === devices.lamp) {
            setOverviewData((oldOverviewState) => {
              const newState = [...oldOverviewState];
              newState[index].data.lamp = data.data.status === "ON";
              return newState;
            });
          }
        } else {
          if (
            view === "detail" &&
            (data.imei.toString() === currentDevice.wall ||
              data.imei.toString() === currentDevice.room)
          ) {
            updateDetail(data);
          }
          updateOverview(data);
        }
      }
    };
  }, [view, overviewData, deviceDetail]);

  /**
   * Initial Data from the DB
   */
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      setLoading(true);
      if (view === "detail" && currentDevice?.wall !== "") {
        const query_wall =
          dataEndpointConfig.data.fields +
          `&imei=eq.${currentDevice?.wall}` +
          dataEndpointConfig.data.order;

        const query_room =
          dataEndpointConfig.data.fields +
          `&imei=eq.${currentDevice?.room}` +
          dataEndpointConfig.data.order;

        getDeviceData(instance, account, query_wall).then((wallData) => {
          getDeviceData(instance, account, query_room).then((roomData) => {
            const detailData = joinAlwaDetailData(
              wallData,
              roomData,
              overviewData[
                getIndexByObjectImei(overviewData, currentDevice.wall)
              ]
            );
            setDeviceDetail(detailData as AlwaDeviceDetailProps);
            setLoading(false);
          });
        });
      } else {
        const query =
          dataEndpointConfig.latestDeviceData.fields +
          `?project=eq.alwa` +
          dataEndpointConfig.latestDeviceData.order;

        getDeviceData(instance, account, query).then((data) => {
          const imeiList = getOverviewImeiList(data);
          const devices = joinAlwaOverviewData(data);
          setOverviewData(devices);
          setLoading(false);
          subscribeToNewData(account, imeiList);
        });
      }
    }
  }, [account, inProgress, instance, currentDevice, view]);

  /**
   * Update on new received data
   */
  const updateOverview = (update: DeviceProps) => {
    // Also account for imei of room data
    const newOverviewData = [...overviewData];
    let isRoomData = false;
    let deviceIndex = getIndexByObjectImei(
      newOverviewData,
      update.imei.toString()
    );
    if (deviceIndex === -1) {
      // maybe room data
      const device = getDevicesFromRoomImei(update.imei.toString());
      deviceIndex = getIndexByObjectImei(newOverviewData, device.wall);
      isRoomData = true;

      if (deviceIndex === -1) {
        return;
      }
    }

    newOverviewData[deviceIndex] = parseAlwaUpdateData(
      update,
      newOverviewData[deviceIndex],
      isRoomData
    );

    setOverviewData(newOverviewData);
  };

  const updateDetail = (update: DeviceProps) => {
    const newDetailData = { ...deviceDetail };

    if (update.imei.toString() === currentDevice.room) {
      newDetailData.roomData = [
        transformAlwaUpdate(update),
        ...newDetailData.roomData,
      ];
      newDetailData.latestRoomData.data = update.data as DataProps;
      newDetailData.latestRoomData.timestamp = update.timestamp;
    } else if (update.imei.toString() === currentDevice.wall) {
      newDetailData.wallData = newDetailData.wallData = [
        transformAlwaUpdate(update),
        ...newDetailData.wallData,
      ];
      newDetailData.latestWallData.data = update.data as DataProps;
      newDetailData.latestWallData.timestamp = update.timestamp;
    }

    setDeviceDetail(newDetailData);
  };

  /**
   * Subscribe to new Data
   */
  const subscribeToNewData = (account: AccountInfo, imeiList: string[]) => {
    client.send(
      JSON.stringify({
        uuid: account.localAccountId,
        imeis: imeiList,
        topic: "subscribe",
      })
    );

    // Get states of alwa smart switches
    AlwaDevice.map((alwa) => {
      setTimeout(function () {
        client.send("get-" + alwa.pump);
        client.send("get-" + alwa.lamp);
      }, 500);
    });
  };

  /**
   * Switch between views
   */
  const changeView = (imei?: string) => {
    setView(imei ? "detail" : "overview");
    if (imei) {
      const index = getIndexByObjectImei(overviewData, imei);
      const alwaDevices = getDevicesFromWallImei(imei);
      setCurrentDevice({
        index: index,
        wall: imei,
        room: alwaDevices.room,
        pump: alwaDevices.pump,
        lamp: alwaDevices.lamp,
      });
    }
  };

  React.useEffect(() => {
    const params = getUrlParams();
    // IMEI set? => Go to detail view
    if (params && params.id) {
      changeView(params.id);
    }
  }, []);

  return (
    <StandardTemplate>
      <Section>
        {view === "overview" && (
          <>
            <HeadlineContainer color={"monochrome500"}>
              <Headline text={"Smart Alwa"} styleAs={"h1"} as={"h1"} />
            </HeadlineContainer>
            <Row center={"xs"}>
              {overviewData &&
                overviewData.map((device: DeviceProps) => {
                  return (
                    <AlwaOverview
                      key={device.imei}
                      device={device}
                      changeView={() => changeView(device.imei)}
                    />
                  );
                })}
            </Row>
          </>
        )}
        {view === "detail" && (
          <AlwaDetails
            deviceDetail={deviceDetail}
            changeView={() => changeView()}
            currentDevice={currentDevice}
            pump={overviewData[currentDevice.index].data?.pump as boolean}
            lamp={overviewData[currentDevice.index].data?.lamp as boolean}
            client={client}
          />
        )}
      </Section>
      <LoadingScreen show={loading} animation={RedAnimation} />
    </StandardTemplate>
  );
};

export default AlwaPage;
